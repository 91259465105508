/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Bootstrap Icons SCSS file. */
@import '../node_modules/bootstrap-icons/font/bootstrap-icons';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.title{
  padding-top : 20px;
  margin-left: 50px;   
 }
 
 .DatePickerClass{
  margin-left: 50px; 
  margin-bottom: 20px;  
 }
 
 .container{
     display: flex;
     flex-direction: column;
 }
 
 .search-container{
     margin-top: 10px;
 }
